.wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

/* .App-header {
  font-size: calc(10px + 2vmin);
} */