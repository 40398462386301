.footerContacts {
    padding: 20px 0 0;

    .title {
        font-size: 19px;
        font-weight: 500;
    }

    .content {
        .contactList {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1rem 0 2rem;
            font-size: 13px;

            li {
                .iconPhone {}

                a {}
            }
        }

        .socialMedia {
            li {
                a {}
            }
        }
    }

    .workingHours {
        .title {}

        .contactList {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1rem 0 0;
            font-size: 13px;

            li {}
        }
    }
}