.languageSwitcher {
    position: relative;
    display: inline-block;

    .switcherButton {
        line-height: 3rem;
        background: none;
        border: none;
        color: #193880;
        padding: 8px 0 8px 10px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        position: relative;
        transition: color 0.3s ease;

        &:hover {
            color: rgb(0, 0, 0);
        }

        .arrow {
            fill: #193880;
            width: 30px;
            height: 30px;
            transition: 0.3s ease;

            &:hover {
                fill: rgb(0, 0, 0);
            }
        }

        .open {
            transform: rotate(180deg);

        }
    }

    .dropdownMenu {
        display: none;
        flex-direction: column;
        position: absolute;
        background: #F8F9FA;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;

        .dropdownItem {
            font-size: inherit;
            padding: 8px 16px;
            cursor: pointer;
            border: none;
            background: none;
            text-align: left;
            width: 100%;
            border-radius: 5px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #f0f0f0;
            }
        }

        .none {
            display: none;
        }
    }

    .show {
        display: flex;
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 767.98px) and (orientation: portrait),
(max-width: 767.98px) and (orientation: landscape),
(max-width: 1000px) and (orientation: landscape) {
    .languageSwitcher {
        .switcherButton {
            .arrow {
                transform: rotate(90deg);
            }

            .open {
                transform: rotate(180deg);
            }
        }

        .dropdownMenu {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            top: calc(-100% - 56px + 2rem);

            .dropdownItem {
                padding: 16px 16px;
            }
        }
    }
}