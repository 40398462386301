.card {
    width: calc(33.33% - 1rem);
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    & img {
        width: 100%;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    }

    & h3 {
        font-size: 1.125rem;
        font-weight: 700;
        color: #193880;
        line-height: 130%;
        margin-bottom: 1rem;
        padding: 2rem 0 0;
    }

    & p {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        color: #202A39;
        line-height: 150%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 1200px) {
    .card {
        width: calc(50% - 0.5rem);
    }
}

@media (max-width: 900px) {
    .card {
        width: calc(50% - 0.5rem);
        padding: 0.5rem;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .card {
        width: calc(33.3% - 0.5rem);
        padding: 0.5rem;

        & img {
            width: 100%;
            height: auto;
        }

        & h3 {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            padding: 0.5rem 0 0;
        }

        & p {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .card {
        & h3 {
            margin-bottom: 0.5rem;
            padding: 1rem 0 0;
        }

        & p {
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 450px) and (orientation: portrait) {
    .card {
        width: 100%;

        & h3 {
            font-size: 0.875rem;
        }

        & p {
            font-size: 0.875rem;
            margin-bottom: 0.875rem;
        }
    }
}