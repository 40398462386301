.breadcrumbs {
    padding: 10px 0;
    background-color: none;
    position: relative;
    z-index: 2;

    .breadcrumbList {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .breadcrumbItem a {
            text-decoration: none;
            color: #007bff;
        }

        .breadcrumbItem a:hover {
            text-decoration: underline;
        }
    }
}