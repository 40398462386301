.notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    height: 80vh;

    .icon {
        width: 100px;
        height: 100px;
        margin-bottom: 2rem;
        color: #193880;
    }

    .title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .message {
        font-size: 1.2rem;

        a {
            background: #193880;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            padding: 10px 16px;
            cursor: pointer;
            font-size: 16px;
            line-height: 150%;
            font-weight: 500;
            transition: background-color 0.3s, border-color 0.3s;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}