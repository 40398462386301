.a11yMenu {
    // letter-spacing: 2px;
    width: 100%;
    height: 64px;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;

    .a11yMenuContainer {
        max-width: 1040px;
        width: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dropDownMenu {
            position: fixed;
            top: 64px;
            max-height: 95%;
            max-width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 30px 31px 40px;
            margin: 0 1px 1px 0;
            border: 2px solid;
            border-color: inherit;
            border-top: none;
            width: 1012px;
            z-index: 1050;

            .menuSection {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
                padding: 16px 0;

                h3 {
                    margin-bottom: 0;
                }

                .fontSizeButtons {
                    display: flex;

                    .fontSizeButton {

                        .active {
                            background: inherit;
                            color: inherit;
                        }
                    }
                }

                .typeFontButtons {
                    display: flex;
                }

                .letterSpacing {
                    display: flex;
                }

                .colorSchemeButtons {
                    display: flex;

                    .colorSchemeButton:not(:nth-child(4)) {
                        margin-right: 24px;
                    }

                    .colorSchemeButton {
                        font-size: 1rem;
                        border: 2px solid;
                        border-color: inherit;
                        min-height: 6rem;
                    }

                    .white {
                        background-color: #fff;
                        color: #222;
                    }

                    .black {
                        background-color: #222;
                        color: #fff;
                    }

                    .blue {
                        background-color: #c8e5ff;
                        color: #195183;
                    }

                    .brown {
                        background-color: #fff5e6;
                        border-color: #301601;
                    }
                }

                button {
                    align-items: center;
                    display: flex;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    padding: 10px 12px;
                    text-align: left;
                    border-radius: 0.25rem;
                }
            }

            .menuSection:not(:nth-child(4)) {
                border-bottom: 1px solid;
                border-color: inherit;
            }

            .menuActions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                .menuActionsButton {
                    border: 2px solid;
                    border-color: inherit;
                    border-radius: 1.375rem;
                    font-family: inherit;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.25;
                    padding: .625rem 1.5rem;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .a11yMenuItem {
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: 500;
            flex-grow: 2;


            span {
                font-size: 16px;
                margin-right: 5px;
            }

            a {
                display: flex;
                align-items: center;
            }

            .burgerMenuButton {
                display: none;
            }

            .fontSizeButtons {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 27px 0 0;
                border-right: 1px solid #8a8a8a;

                .fontSizeButton {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    padding: 1px 6px;
                    font-weight: 600;
                    background: inherit;
                    color: inherit;
                }

                .active {
                    color: #222;
                    background: #eee;
                }
            }

            .colorSchemeButtons {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 27px 0 0;
                border-right: 1px solid #8a8a8a;

                .colorSchemeButton {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    padding: 1px 6px;
                    font-weight: 600;
                    font-size: 24px;
                }

                .white {
                    background-color: #fff;
                    color: #222;
                }

                .black {
                    background-color: #222;
                    color: #fff;
                }

                .blue {
                    background-color: #c8e5ff;
                    color: #195183;
                }

                .brown {
                    background-color: #fff5e6;
                    border-color: #301601;
                }
            }

            .settingsSwitcher {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 17px 0 25px;
                border-right: 1px solid #8a8a8a;

                .settingsButton {
                    background: none;
                    color: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;

                    .open {
                        transform: rotate(180deg);
                    }
                }
            }

            .search {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 0 0 27px;

                .icon {
                    width: 28px;
                    height: 28px;
                }
            }
        }

        .a11yMenuItem:nth-last-child(5) {
            justify-content: space-between;
        }

        .a11yMenuItem:nth-last-child(4) {
            justify-content: space-between;
            padding-left: 27px;
        }

        .a11yMenuItem:nth-last-child(3) {
            flex-grow: 0;
        }

        .a11yMenuItem:nth-last-child(2) {
            flex-grow: 0;
        }

        .a11yMenuItem:nth-last-child(1) {
            flex-grow: 0;
        }

    }
}

@media (max-width: 1023.98px) {
    .a11yMenu {
        .a11yMenuContainer {
            .dropDownMenu {
                left: 0;
                width: 100%;
                height: 100%;
                padding: 30px 15px 40px;

                .menuSection {
                    .fontSizeButtons {
                        .fontSizeButton {}
                    }

                    .typeFontButtons {}

                    .letterSpacing {}

                    .colorSchemeButtons {
                        flex-direction: column;
                        width: 100%;
                        .colorSchemeButton:not(:nth-child(4)) {
                            margin-right: 0;
                            margin-bottom: 10px;
                        }

                        .colorSchemeButton {}
                    }



                    button {
                        align-items: center;
                        display: flex;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        padding: 10px 12px;
                        text-align: left;
                        border-radius: 0.25rem;
                    }
                }

                .menuActions {
                    .menuActionsButton {}
                }
            }

            .a11yMenuItem {
                .burgerMenuButton {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1px 6px;
                    font-weight: 600;
                    background: inherit;
                    color: inherit;
                    height: 100%;

                    span {
                        margin-left: 10px;
                    }
                }

                .settingsSwitcher {

                    border-right: none;

                    .settingsButton {}
                }
            }

            .a11yMenuItem:nth-last-child(2) {
                display: none;
            }

            .a11yMenuItem:nth-last-child(3) {
                flex-grow: 2;
            }

            .a11yMenuItem:nth-last-child(4) {
                display: none;
            }

            .a11yMenuItem:nth-last-child(5) {
                display: none;
            }
        }
    }
}
@media (max-width: 767.98px) {
    .a11yMenu {
        .a11yMenuContainer {
            .dropDownMenu {
                

                .menuSection {
                    .fontSizeButtons {
                        flex-wrap: wrap;
                        .fontSizeButton {}
                    }

                    .typeFontButtons {
                        flex-wrap: wrap;

                    }

                    .letterSpacing {
                        flex-wrap: wrap;

                    }

                    .colorSchemeButtons {
                        .colorSchemeButton:not(:nth-child(4)) {
                        }

                        .colorSchemeButton {}
                    }



                    button {
                        align-items: center;
                        display: flex;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        padding: 10px 12px;
                        text-align: left;
                        border-radius: 0.25rem;
                    }
                }

                .menuActions {
                    flex-direction: column;
                    .menuActionsButton {
                    width: 100%;

                    }
                }
            }

            .a11yMenuItem {
                .burgerMenuButton {
                    span {
                        margin-left: 10px;
                    }
                    .icon {
                        display: none;
                    }
                }

                .settingsSwitcher {
                padding: 0 4px 0 8px;

                    border-right: none;

                    .settingsButton {
                        .icon {
                            display: none;
                        }
                    }
                }
                .search {
                    padding: 0 0 0 8px;
    
                    .icon {
                        display: none;
                    }
                }
            }

            .a11yMenuItem:nth-last-child(2) {
                display: none;
            }

            .a11yMenuItem:nth-last-child(3) {
                flex-grow: 2;
            }

            .a11yMenuItem:nth-last-child(4) {
                display: none;
            }

            .a11yMenuItem:nth-last-child(5) {
                display: none;
            }
        }
    }
}