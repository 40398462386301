/* SearchComponent.module.css */
.A11ySearchContainer {
    min-height: 30vh;
    width: 100%;

    .searchInput {
        width: 100%;
        padding: 0.875rem 1.125rem;
        color: inherit;
        font-size: 1.25rem;
        font-weight: 500;
        background: none;
        border: 2px solid;
        border-color: inherit;
        border-radius: 0.25rem;
        margin-bottom: 3rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .searchResults {
        display: flex;
        flex-direction: column;

        .resultItem {
            background: none;
            // border: 2px solid;
            // border-color: inherit;
            // border-radius: 0.25rem;
            // padding: 0.875rem 1.125rem;
            width: 100%;
            margin-bottom: 1.5rem;
        }
    }
}