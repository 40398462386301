@import './fonts.scss';

/*Обнуление*/
:root {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;

    @media (max-width: 767.98px) {
        font-size: 14px;
    }
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

input,
button,
textarea {
    font-family: inherit;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a {
    color: inherit;
}

a,
a:visited {
    text-decoration: none;
}

a:focus,
a:active {
    outline: none;
}

a:hover {
    text-decoration: none;
}

ul,
ol,
li {
    list-style: none;
}

img {
    vertical-align: top;
}

html,
body {
    height: 100%;
    line-height: 1;
    color: #000000;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

/*--------------------*/