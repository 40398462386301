@import '/src/scss/variables.scss';

.banner {
    width: 100%;
    position: relative;
    background-image: url(../../../assets/client-images/Catalog/bachni-2.jpg);
    background-size: cover;
    background-position: bottom;
    padding: 3rem 2rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) inset;
        background: linear-gradient(90deg, #fff, #D9E9F3, #fff);
        opacity: .9;
        z-index: 1;
    }

    .container {
        .title {
            position: relative;
            color: #193880;
            z-index: 2;
            font-size: 24px;
            font-weight: 500;
            padding: 5px 0;
        }
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .banner {
        padding: $padding-mobile-products-card;
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .banner {
        padding: $padding-mobile-products-card;
    }
}

// @media (max-width: 450px) {
//     .banner {
//         padding: $padding-mobile-products-card;
//     }
// }