@import '/src/scss/variables.scss';

.certificates {
    margin: $margin-about-content;
    // padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        & ul {
            padding: 0 0 0 1rem;
            margin: 0 0 2rem 0;

            li {
                list-style-type: square;
                margin-bottom: 20px;
                padding-left: 10px;
                font-style: normal;

                &::marker {
                    color: #193880;
                }
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: flex-start;
            gap: 1rem 2rem;
        }

        .title {
            font-size: 2.5rem;
            font-weight: 500;
            color: #202A39;
            margin-bottom: 2rem;
            line-height: 150%;
            text-transform: uppercase;
        }

        & h2 {
            font-size: 1.875rem;
            font-weight: 600;
            color: #193880;
            flex: 0 0 100%;
        }

        // & h4 {
        //     font-size: 1rem;
        //     font-weight: 600;
        //     line-height: 150%;
        //     margin-bottom: 1rem;
        // }

        // & h5 {
        //     font-size: 1rem;
        //     font-weight: 500;
        //     line-height: 150%;
        //     margin-bottom: 1rem;
        // }

        & p {
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            color: #202A39;
            line-height: 150%;
            margin-bottom: 2rem;
        }

        .tel {
            margin-bottom: 0.625rem;

            & a {
                padding-right: 0.3rem;
            }
        }
    }
}

@media (max-width: 767.98px) and (orientation: portrait) {
    .certificates {
        padding: 0 0.5rem;
        
        .container {
            .content {
                flex-direction: column;
                gap: 1.5rem;
            }
        }
    }
}

@media (max-width: 450px) {
    .certificates {
        .container {
            & ul {
                padding: 0 0 0 1rem;
                margin: 0 0 1.5rem 0;

                li {
                    margin-bottom: 20px;
                    padding-left: 10px;

                    &::marker {
                        color: #193880;
                    }
                }
            }

            .content {
                justify-content: center;
            }

            & h2 {
                font-size: 1.375rem;
                margin-bottom: 0;
            }

            & h3 {
                font-size: 1rem;
                margin-bottom: 0.5rem;
                padding: 1rem 0 0;
            }

            & h4 {
                font-size: 1rem;
                margin-bottom: 1rem;
            }

            & h5 {
                font-size: 1rem;
                margin-bottom: 1rem;
            }

            & p {
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                color: #202A39;
                line-height: 150%;
                margin-bottom: 1.5rem;
            }

            .tel {
                margin-bottom: 0.625rem;

                & a {
                    padding-right: 0.3rem;
                }
            }
        }
    }
}