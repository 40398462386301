.a11yFooter {
    margin-top: 60px;
    width: 100%;
    border-top: 1px solid black;

    .footerContainer {
        ul {
            li {
                line-height: 1.5;
                margin-bottom: 0.5rem;
                font-weight: 500;
            }
        }
        .footerItem {
            margin-top: 2rem;
        }
        .footerContacts {
            // ul {
            //     li {
            //         line-height: 1.5;
            //         margin-bottom: 0.5rem;
            //     }
            // }
        }

        .footerWorkingHours {
            // ul {
            //     li {
            //         line-height: 1.5;
            //         margin-bottom: 0.5rem;
            //     }
            // }
        }

        .footerGovLinks {
            ul {
                li {
                    a {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 0.625rem;

                        .linkIcon {
                            margin-right: 0.625rem;
                        }
                    }
                }
            }
        }

        .footerBottom {
            display: flex;
            justify-content: space-between;
            border-top: 2px solid;
            border-color: inherit;
            padding: 1.875rem 0;
            margin: 1.875rem 0 0 0;

            p {
                margin-bottom: 0;

            }
        }

        h3 {
            font-size: 1.375rem;
            font-weight: 600;
            line-height: 1.33;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 1.5rem;
        }
    }
}

@media (max-width: 767.98px) {
    .a11yFooter {
        .footerContainer {
            .footerGovLinks {
                ul {
                    li {
                        a {
                            .linkIcon {}
                        }
                    }
                }
            }

            .footerBottom {
                flex-direction: column;

                p {}
            }

            h3 {}

            p {}
        }
    }
}