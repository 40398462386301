@import '/src/scss/variables.scss';

.history {
    padding: 4rem 2rem;
    width: 100%;
    text-align: center;


    .container {
        max-width: 1300px;
        margin: 0 auto;

        .historyContent {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            .historyImage {
                border-radius: 12px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;

                img {
                    border-radius: 10px;
                    width: 392px;
                    height: 100%;
                }
            }

            .historyText {

                h2 {
                    color: #193880;
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin-bottom: 2rem;
                }

                p {
                    text-align: left;
                    font-size: 1.125rem;
                    line-height: 150%;
                }
            }
        }

    }
}

@media (max-width: 1220px) {
    .history {
        .container {
            .historyContent {
                flex-wrap: wrap;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .history {
        padding: $padding-mobile-main-blocks;

        .container {
            .historyContent {
                gap: 1rem;

                .historyText {
                    h2 {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .history {
        padding: $padding-mobile-main-blocks;

        .container {
            .historyContent {
                gap: 1rem;

                .historyText {
                    h2 {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .history {
        .container {
            .historyContent {
                .historyImage {
                    img {
                        max-width: 392px;
                        width: 100%;
                    }
                }
            }
        }
    }
}