@import './reset.scss';
@import './variables.scss';
@import './mixins.scss';

/*----------------------для страниц A11Y------------------------*/
.white-scheme {
    @include white-scheme($white, $black);
}

.black-scheme {
    @include black-scheme($black, $white);
}

.blue-scheme {
    @include blue-scheme($blue-light, $blue);
}

.brown-scheme {
    @include brown-scheme($brown-light, $brown);
}

/*----------------------для Меню A11Y------------------------*/
.white-scheme-reverse {
    @include white-scheme($black, $white);
}

.black-scheme-reverse {
    @include black-scheme($white, $black);
}

.blue-scheme-reverse {
    @include blue-scheme($blue, $blue-light);
}

.brown-scheme-reverse {
    @include brown-scheme($brown, $brown-light);
}

/*----------------------для иконок A11Y------------------------*/
.white-scheme-icon {
    @include scheme-icon($black);
}

.black-scheme-icon {
    @include scheme-icon($white);
}

.blue-scheme-icon {
    @include scheme-icon($blue);
}

.brown-scheme-icon {
    @include scheme-icon($brown);
}

/*----------------------для иконок Меню A11Y------------------------*/
.white-scheme-icon-reverse {
    @include scheme-icon-reverse($white);
}

.black-scheme-icon-reverse {
    @include scheme-icon-reverse($black);
}

.blue-scheme-icon-reverse {
    @include scheme-icon-reverse($blue-light);
}

.brown-scheme-icon-reverse {
    @include scheme-icon-reverse($brown-light);
}