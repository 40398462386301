.a11yButton {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    margin-left: 16px;
    white-space: nowrap;

    svg {
        margin: 0 0.5rem 0 0;
    }

    &:hover {
        text-decoration: none;
    }
}

@media (max-width: 767.98px) {
    .a11yButton {
        margin-top: 1rem;
        margin-left: 0;
    }
}