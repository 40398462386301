.a11yLanguageSwitcher {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #8a8a8a;

    .switcherButton {
        padding: 0 17px 0 27px;
        height: 100%;
        background: none;
        border: none;
        color: inherit;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        position: relative;

        &:hover {
            text-decoration: underline;
        }

        .arrow {
            width: 24px;
            height: 24px;
        }

        .open {
            transform: rotate(180deg);
        }
    }

    .dropdownMenu {
        display: none;
        flex-direction: column;
        position: absolute;
        border: 2px solid;
        border-top: none;
        border-color: inherit;
        top: 100%;
        left: -2px;
        width: 103%;
        padding: 0 11px 8px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;

        .dropdownItem {
            font-weight: 500;
            font-size: 16px;
            padding: 8px 46px 8px 0;
            cursor: pointer;
            border: none;
            background: none;
            text-align: right;
            width: 100%;
            color: inherit;
        }

        .none {
            display: none;
        }
    }

    .show {
        display: flex;
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 1023.98px) {
    .a11yLanguageSwitcher {
        justify-content: flex-start;
        border-right: none;

        .switcherButton {
            width: 100%;
            margin: 0 0 0 16px;
            padding: 24px 0;
            height: 100%;
            border: none;
            font-size: 1.25rem;
            font-weight: 600;
            display: flex;
            align-items: center;
        }

        .dropdownMenu {
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            border: 2px solid;
            border-top: none;
            border-color: inherit;
            top: 100%;
            left: -2px;
            width: 103%;

            .dropdownItem {
                font-weight: 600;
                font-size: 1.25rem;
                padding: 8px 46px 8px 55px;
                text-align: left;
            }
        }
    }
}

// @media (max-width: 767.98px) and (orientation: portrait),
// (max-width: 767.98px) and (orientation: landscape),
// (max-width: 1000px) and (orientation: landscape) {
//     .a11yLanguageSwitcher {
//         .switcherButton {
//             .arrow {
//                 transform: rotate(90deg);
//             }

//             .open {
//                 transform: rotate(180deg);
//             }
//         }

//         .dropdownMenu {
//             box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
//             top: calc(-100% - 56px + 2rem);

//             .dropdownItem {
//                 padding: 16px 16px;
//             }
//         }
//     }
// }