@mixin white-scheme($bg-color, $text-color) {
    background: $bg-color;
    color: $text-color;
}

@mixin black-scheme($bg-color, $text-color) {
    background: $bg-color;
    color: $text-color;
}

@mixin blue-scheme($bg-color, $text-color) {
    background: $bg-color;
    color: $text-color;
}

@mixin brown-scheme($bg-color, $text-color) {
    background: $bg-color;
    color: $text-color;
}

@mixin scheme-icon($text-color) {
    fill: $text-color;
}

@mixin scheme-icon-reverse($bg-color) {
    fill: $bg-color;
}