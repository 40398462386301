.aboutPage {
    padding: 2rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        // .tabNav {
        //     margin-top: 20px;

        //     .tabList {
        //         display: flex;
        //         flex-wrap: wrap;
        //         border-bottom: 1px solid #ccc;

        //         .tabItem {
        //             margin-right: 20px;

        //             .tabLink {
        //                 display: block;
        //                 padding: 10px 15px;
        //                 text-decoration: none;
        //                 color: #000;
        //                 border-bottom: 2px solid transparent;

        //                 &.active {
        //                     border-bottom: 2px solid #000;
        //                     font-weight: bold;
        //                 }

        //                 &:hover {
        //                     border-bottom: 2px solid #777;
        //                 }
        //             }
        //         }
        //     }
        // }

        h4 {
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 1.25rem;
        }

        p {
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            color: #202A39;
        }

        ul {
            line-height: 150%;
            padding: 0.5rem 0 0 1.5rem;
            margin: 0;

            li {
                list-style-type: square;
                padding-left: 10px;
                font-style: normal;
                margin-bottom: 0.5rem;
                ul {
                    li {
                        list-style-type: circle;
                    }
                }

                a {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                }

                &::marker {
                    color: #193880;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .aboutPage {
        padding: 1rem 0.5rem;
    }
}