/* LoadMoreButton.module.scss */
.loadMoreButton {
    background-color: #193880;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s;
    outline: none;
    display: block;
    line-height: 150%;
  }
  
  .loadMoreButton:hover {
    background-color: #1e4d8f;
  }
  
  .loadMoreButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  