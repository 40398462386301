.appealsPage {
    padding: 2rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        min-height: 50vh;
        margin: 0 auto;

        & h3 {
            font-size: 1.375rem;
            font-weight: 700;
            color: #193880;
            line-height: 130%;
            margin-bottom: 1rem;
        }

        & p {
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            color: #202A39;
        }

        & ul {
            padding: 0 0 0 1rem;
            margin: 0 0 2rem 0;

            li {
                list-style-type: square;
                margin-bottom: 20px;
                padding-left: 10px;
                font-style: normal;
                line-height: 1.5;

                a {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                }

                &::marker {
                    color: #193880;
                }
            }
        }
    }
}

@media (max-width: 424.98px) and (orientation: portrait),
(max-width: 424.98px) and (orientation: landscape) {
    .appealsPage {
        padding: 2rem 1rem;
    }
}