@import '/src/scss/variables.scss';

.management {
    margin: $margin-about-content;
    // padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 1.875rem;
            font-weight: 600;
            color: #193880;
            flex: 0 0 100%;
        }

        .card {
            padding: 2rem;

            &:nth-child(2n + 1) {
                background: #F8F9FA;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                color: #193880;
                line-height: 130%;
                margin-bottom: 0.875rem;
            }

            h4 {
                font-size: 1rem;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 1rem;
            }

            h5 {
                font-size: 1rem;
                font-weight: 500;
                line-height: 150%;
                margin-bottom: 1rem;
            }

            .phones {
                padding: 0;
                margin: 0;

                li {
                    position: relative;
                    list-style-type: none;
                    margin-bottom: 20px;
                    padding-left: 10px;
                    font-style: normal;
                    margin-bottom: 0.625rem;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1.125rem;
                        height: 1.125rem;
                        background-image: url('../../../../assets/icons/call_management.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    a {
                        padding: 0 0.3rem 0 1rem;
                    }
                }
            }

            p {
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                color: #202A39;
                line-height: 150%;
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 450px) {
    .management {
        // margin: $margin-about-content-mobile;

        .container {
            h2 {
                padding: 0 0 0 0.5rem;
                font-size: 1.375rem;
            }

            .card {
                padding: 1rem 0.5rem;
                ;

                h3 {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }

                h4 {}

                h5 {}

                .phones {
                    padding: 0;
                    margin: 0;

                    li {
                        position: relative;
                        list-style-type: none;
                        margin-bottom: 20px;
                        padding-left: 10px;
                        font-style: normal;
                        margin-bottom: 0.625rem;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1.125rem;
                            height: 1.125rem;
                            background-image: url('../../../../assets/icons/call_management.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                        }

                        a {
                            padding: 0 0.3rem 0 1rem;
                        }
                    }
                }

                p {}
            }
        }
    }
}