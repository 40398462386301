.a11yAboutPage {
    .tabs {
        .tabButtons {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 0.25rem;
            padding: .875rem 0 1rem;
            width: 100%;
            border-bottom: 2px solid;
            border-color: inherit;

            .tabButton {
                border: 2px solid;
                border-color: inherit;
                border-radius: 1.375rem;
                cursor: pointer;
                display: inline-block;
                font-family: inherit;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.25;
                padding: .625rem 1.5rem;
                text-decoration: none;
            }
        }

    }

    .tabContent {}
}

@media (max-width: 767.98px) {
    .a11yAboutPage {
        .tabs {
            .tabButtons {
                flex-direction: column;

                .tabButton {}
            }
        }

        .tabContent {}
    }
}