.a11ySideBar {
    margin-bottom: 4rem;

    .sideBarList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 2px solid;
        border-color: inherit;
        padding: .875rem 0 1rem;

        .sideBarItem {
            .link {
                padding: 0.875rem 1rem;
                display: block;
                font-size: 1.25rem;
                font-weight: 600;
                border-radius: .375rem;
            }
        }
    }

    .megaMenuList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 0.25rem;
        padding: .875rem 0 1rem;
        width: 100%;
        border-bottom: 2px solid;
        border-color: inherit;

        .megaMenuItem {
            cursor: pointer;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25;
            border-radius: 0.25rem;
            padding: 0.625rem 1rem;
        }
    }
}

@media (max-width: 1023.98px) {
    .a11ySideBar {
        position: fixed;
        height: calc(100% - 64px);
        width: 100%;
        left: 0;
        top: 64px;
        margin-bottom: 0;

        .sideBarList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: none;
            padding: .875rem 0 1rem;

            .sideBarItem {
                width: 100%;

                .link {
                    margin: 0 1rem;
                    padding: 1.5rem 25px 1.5rem 0;
                    display: block;
                    font-size: 1.25rem;
                    font-weight: 600;
                    border-radius: 0;
                    width: 100%;
                    color: inherit;
                    background: inherit;
                    border-bottom: 1px solid;
                    border-color: inherit;
                }
            }
        }
    }
}