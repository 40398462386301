/* Tabs.scss */
@import '/src/scss/variables.scss';

.tabs {
    // margin: $margin-about-content;

    .tabButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        gap: 4px;

        .tabButton {
            flex: 1;
            height: 60px;
            padding: 0.75rem 1.5rem;
            cursor: pointer;
            font-size: 1rem;
            line-height: 130%;
            background: #f9f9f9;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: none;
            border-radius: 5px 5px 0 0;
            transition: background 0.3s, color 0.3s, box-shadow 0.3s;

            &.active {
                background: #193880;
                color: white;
                border-color: #193880;
                font-weight: 600;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }

            &:not(.active):hover {
                background: #e0e0e0;
                border-color: #bbb;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) inset;
            }
        }
    }

}
.tabContent {
    // padding: 1rem;
    // border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: #fff;
}

@media (max-width: 1000px) and (orientation: landscape) {
    .tabs {
        .tabButtons {
            flex-direction: column;
            border-bottom: none;
            gap: 8px;

            .tabButton {
                width: 100%;
                border: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-radius: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .tabs {
        .tabButtons {
            flex-direction: column;
            border-bottom: none;
            gap: 8px;

            .tabButton {
                width: 100%;
                border: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-radius: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}