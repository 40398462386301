.a11yCatalog {
    .catalogGrid {
        .catalogCard {
            margin-top: 3.75rem;

            .catalogCardContent {}

            .catalogCardLink {
                background: inherit;
                color: inherit;
                border: 2px solid;
                border-color: inherit;
                border-radius: 1.375rem;
                cursor: pointer;
                display: inline-block;
                font-family: inherit;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.25;
                padding: 0.625rem 1.5rem;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}