@import 'src/scss/variables.scss';

@mixin main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 64px 0 0 0;

    a {
        color: $link-normal
    }
}

.mainCollapsed {
    @include main;
    margin-left: 100px;
    width: calc(100% - 100px);
}

.mainExpanded {
    @include main;
    margin-left: 250px;
    width: calc(100% - 250px);
}

@media (max-width: 767px) and (orientation: portrait) {
    .mainCollapsed {
        @include main;
        padding: 56px 0 0 0;
        margin-left: 0px;
        width: 100%;
    }

    .mainExpanded {
        @include main;
        padding: 56px 0 0 0;
        margin-left: 0px;
        width: 100%;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .mainCollapsed {
        @include main;
        padding: 56px 0 0 0;
        margin-left: 0px;
        width: 100%;
    }

    .mainExpanded {
        @include main;
        padding: 56px 0 0 0;
        margin-left: 0px;
        width: 100%;
    }
}