.sidebar {
    // width: 250px;
    background-color: #f8f9fa;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 64px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 998;

    .open {
        width: 250px;
    }

    .closed {
        width: 100px;
    }

    .navList {
        list-style: none;
        padding: 0;

        &.open {}

        &.closed {
            max-width: 100px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
        }
    }

    .showHc {
        display: none;
    }
}

@media (max-width: 767.98px) and (orientation: portrait),
(max-width: 767.98px) and (orientation: landscape),
(max-width: 1000px) and (orientation: landscape) {
    .sidebar {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        background-color: #f8f9fa;
        position: absolute;
        padding-top: 56px;
        transition: transform 0.3s ease;

        &.open {
            width: 85%;
            transform: translateX(18%);
            position: fixed;
            overflow-y: auto;
        }

        &.closed {
            width: 85%;
            transform: translateX(118%);
            position: fixed;
            overflow-y: auto;
        }

        .navList {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &.open {}

            &.closed {
                max-width: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                text-align: center;
            }
        }

        .showHc {
            display: flex;
            padding: 0 1rem;
        }
    }
}