.header {
    width: 100%;
    position: fixed;
    z-index: 999;
    background: linear-gradient(to right, #D8E9F3, #ffffff, #D8E9F3);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

    .container {
        padding: 0 2rem 0;
        min-height: 64px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;

        .right {
            margin-left: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            .hideHc {
                display: block;
            }
        }
    }
}

@media (max-width: 767.98px) and (orientation: portrait),
(max-width: 767.98px) and (orientation: landscape),
(max-width: 1000px) and (orientation: landscape) {
    .header {
        .container {
            min-height: 56px;
            padding: 0 1rem 0;
            flex-direction: row-reverse;

            .right {
                .hideHc {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .header {
        .container {
            .right {
                margin-left: 0;
            }
        }
    }
}