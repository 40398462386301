$padding-mobile-main-blocks: 1.5rem 1rem;
/*--main blocks callToAction history whyUs--*/
$padding-mobile-products-card: 2rem 1rem 1rem;
/*--towers metalStructures banner--*/
$padding-mobile-AntiCorruption-certificates: 1rem 1rem 0.5rem;
/*--certificates AntiCorruptionPage ContactsPage --*/
$padding-mobile: 2rem 1rem 0.5rem;
$padding-mobile-catalog: 1rem 1rem 1.5rem;

$margin-about-content: 2rem 0 0 0;
$margin-about-content-mobile: 1rem 0 0 0;

// Colors a11y
$white: #fff;
$black: #222;
$blue: #195183;
$blue-light: #c8e5ff;
$brown: #301601;
$brown-light: #fff5e6;
$link-normal: #09AFDF;