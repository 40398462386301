.a11yCertificates {
    ul {
        line-height: 150%;
        padding: 0.5rem 0 0 1.5rem;
        margin: 0;

        li {
            list-style-type: disc;
        }

    }

    .certificateList {
        padding: 0;

        .certificateItem {
            list-style-type: none;
            line-height: 1.5;
            margin-bottom: 0.5rem;
            font-weight: 500;
            margin-bottom: 2rem;

            a {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 0.625rem;

                .linkIcon {
                    margin-right: 0.625rem;
                }

                .title {
                    margin-right: 0.625rem;
                }
            }
        }
    }
}