// @import 'src/scss/mixins.scss';
// @import 'src/scss/variables.scss';

.a11yWrapper {
    a:hover {
        text-decoration: underline;
    }

    a.active {
        text-decoration: none;
    }

    .a11yPageTemplate {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 1040px;
        width: 100%;
        margin: 0 auto;
        padding: 64px 16px 0;

        h1 {
            font-size: 2.25rem;
            font-weight: 600;
            line-height: normal;
            margin: 4rem 0 1.5rem;
        }

        h2 {
            font-size: 2rem;
            font-weight: 600;
            line-height: normal;
            margin: 4rem 0 1.5rem;
        }

        h3 {
            font-size: 1.375rem;
            font-weight: 600;
            line-height: 1.33;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 1.5rem;
        }

        main {
            flex-grow: 1;
            width: 100%;
        }
    }
}

@media (max-width: 1023.98px) {
    .pageContainer {

        main {
            margin-top: 2rem;
        }
    }

}