.sliderContainer {
    .mySwiper {
        width: 100%;
        height: 300px;
        position: relative;
        overflow: hidden;

        .swiper-wrapper {
            .swiper-slide {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .desktop-img {
                    display: block;
                }

                .mobile-img {
                    display: none;
                }

                & img {
                    width: auto;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        }
    }
}

@media (min-width: 1720px) {
    .sliderContainer {
        .mySwiper {
            .swiper-wrapper {
                .swiper-slide {
                    height: auto;
                    align-items: center;

                    .desktop-img {
                        display: block;
                    }

                    .mobile-img {
                        display: none;
                    }

                    & img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 1335px) {
    .sliderContainer {
        .mySwiper {
            .swiper-wrapper {
                .swiper-slide {
                    height: auto;
                    align-items: flex-start;

                    .desktop-img {
                        display: block;
                    }

                    .mobile-img {
                        display: none;
                    }

                    & img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .sliderContainer {
        .mySwiper {
            .swiper-wrapper {
                .swiper-slide {
                    .desktop-img {
                        display: none;
                    }

                    .mobile-img {
                        display: block;
                    }

                    & img {
                        width: 100%;
                        height: 320px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .sliderContainer {
        .mySwiper {
            .swiper-wrapper {
                .swiper-slide {
                    .desktop-img {
                        display: none;
                    }

                    .mobile-img {
                        display: block;
                    }

                    & img {
                        width: auto;
                        height: 320px;
                    }
                }
            }
        }
    }
}