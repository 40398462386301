@import '/src/scss/variables.scss';

.whyUsSection {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 4rem 2rem;
    background: linear-gradient(90deg, #fff, #D9E9F3, #fff);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) inset;

    .container {
        max-width: 1300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        h2 {
            color: #193880;
            font-size: 1.5rem;
            font-weight: 700;
            // margin-bottom: 1rem;
        }

        p {
            font-size: 1.125rem;
            line-height: 150%;
            // margin-bottom: 2rem;
        }

        .text {
            text-align: left;
        }

        .cards {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 6rem;

            .card {
                background-color: white;
                padding: 1rem;
                border-radius: 8px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                width: 200px;
                text-align: center;
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateY(-10px);
                }

                .icon {
                    width: 100px;
                    height: 100px;
                    margin-bottom: 1rem;
                }

                h3 {
                    font-size: 2rem;
                    margin-bottom: 0.5rem;
                    color: #193880;
                }

                p {
                    font-size: 1rem;
                    color: #555;
                    margin-bottom: 2rem;
                }
            }
        }
    }

}

@media (max-width: 1000px) and (orientation: landscape) {
    .whyUsSection {
        padding: $padding-mobile-main-blocks;

        .container {
            gap: 1rem;

            .cards {
                gap: 1rem;

                .card {
                    transition: none;

                    &:hover {
                        transform: none;
                    }

                    .icon {
                        width: 95px;
                        height: 95px;
                        margin-bottom: 0.5rem;
                    }

                    p {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .whyUsSection {
        padding: $padding-mobile-main-blocks;

        .container {
            gap: 1rem;

            .cards {
                gap: 1rem;

                .card {
                    transition: none;

                    &:hover {
                        transform: none;
                    }

                    .icon {
                        width: 95px;
                        height: 95px;
                        margin-bottom: 0.5rem;
                    }

                    p {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}


// @media (max-width: 450px) {
//     .whyUsSection {
//         padding: $padding-mobile-main-blocks;

//         .container {
//             gap: 2rem;

//             .cards {
//                 gap: 2rem;
//             }
//         }
//     }
// }