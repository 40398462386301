.a11yAntiCorruptionPage {

    .legalFrameworkList {
        padding-left: 1.375rem;
        margin-bottom: 0.5rem;

        .legalFrameworkItem {
            list-style-type: disc;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 0.5rem;
        }
    }
}