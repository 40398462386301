@import '/src/scss/variables.scss';

.productionCapacity {
    margin: $margin-about-content;
    // padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        h2 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #193880;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.35rem;
            font-weight: 500;
            color: #193880;
            margin: 1.5rem 0 0.5rem;
        }

        ul {
            line-height: 150%;
            padding: 0.5rem 0 0 1.5rem;
            margin: 0;


            li {
                list-style-type: square;

                ul {
                    li {

                        list-style-type: circle;
                    }
                }

                margin-bottom: 0.5rem;
            }
        }

    }
}

@media (max-width: 767.98px) {
    .productionCapacity {
        padding: 0 0.5rem;
        
    }
}
@media (max-width: 450px) {
    .productionCapacity {
        .container {
            gap: 1rem;

            h2 {
                font-size: 1.375rem;
                margin-bottom: 1rem;
            }

            h3 {
                font-size: 1.125rem;
                margin: 1.5rem 0 0.5rem;
            }

            ul {
                padding: 0.5rem 0 0 1.5rem;
                margin: 0;

                li {
                    margin-bottom: 0.5rem;
                }
            }

        }
    }
}