@import '/src/scss/variables.scss';

.timeline {
  background: #fff;
  color: rgb(53, 53, 53);
  margin: $margin-about-content;
  .title {
    font-size: 1.875rem;
    font-weight: 600;
    color: #193880;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-weight: 600;
    padding-top: 0.25em;
  }
  
  .date {
    display: block;
    color: #193880;
    font-weight: 600;
  }
  
  #description {
    margin: 1.5em 0 2em 0;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    background-color: #193880;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  
    &:hover {
      background: #1456a0;
    }
  }
}




// @media screen and (max-width: 1170px) {
//   .icon {
//     width: 25px;
//     height: 25px;
//     left: 50%;
//     top: 50%;
//   }
// }

// @media only screen and (max-width: 1700px) {
//   .vertical-timeline-element-date {
//     display: block !important;
//     float: none !important;
//     color: rgb(44, 44, 44);
//     margin-top: 1.5em;
//   }
// }