.footerGovLinks {
    margin-bottom: 1rem;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        li {
            a {
                max-width: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 14px 20px 0 0;
                gap: 0.5rem;
                opacity: 0.6;
                filter: grayscale(100);
                transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

                img {
                    width: 41px;
                    height: 100%;
                }

                span {
                    font-size: 0.625rem;
                }

                &:hover {
                    opacity: 1;
                    filter: grayscale(0);
                    transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
            }
        }
    }
}