/* FilterButton.module.scss */
.filterButtonContainer {
    width: 100%;
    position: relative;
    display: inline-block;

    .filterButton {
        position: relative;
        display: flex;
        align-items: center;
        background-color: #193880;
        color: #fff;
        border: 1px solid #193880;
        border-radius: 5px;
        padding: 10px 16px;
        cursor: pointer;
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
        transition: background-color 0.3s, border-color 0.3s;
        user-select: none;

        .selectedCategory {
            flex: 1;
        }

        .arrowIcon {
            fill: white;
            margin-left: 10px;
            width: 26px;
            height: 26px;
            transition: transform 0.3s ease;
        }
        .open {
            transform: rotate(180deg);
        }
    }

    .filterButton:hover {
        background-color: #1e4d8f;
        border-color: #1e4d8f;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #F8F9FA;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        min-width: 150px;
        z-index: 3;

        .dropdownItem {
            display: block;
            width: 100%;
            padding: 10px 15px;
            background: white;
            border: none;
            border-radius: 5px;

            cursor: pointer;
            text-align: left;
            font-size: 16px;
            transition: background-color 0.3s;
        }

        .dropdownItem:hover {
            background-color: #f0f0f0;
        }
    }

}