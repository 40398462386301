// @font-face {
//     font-family: 'Montserrat';
//     src: url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
//     font-weight: 100 900;
//     font-style: normal;
// }

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/staticWoff2/Montserrat-Regular.woff2') format('woff2'),
        url('../assets/fonts/staticWoff/Montserrat-Regular.woff') format('woff'),
        url('../assets/fonts/static/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/staticWoff2/Montserrat-Medium.woff2') format('woff2'),
        url('../assets/fonts/staticWoff/Montserrat-Medium.woff') format('woff'),
        url('../assets/fonts/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/staticWoff2/Montserrat-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/staticWoff/Montserrat-SemiBold.woff') format('woff'),
        url('../assets/fonts/static/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/staticWoff2/Montserrat-Bold.woff2') format('woff2'),
        url('../assets/fonts/staticWoff/Montserrat-Bold.woff') format('woff'),
        url('../assets/fonts/static/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}