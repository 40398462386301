@import '/src/scss/variables.scss';

.antiCorruptionPage {

    padding: 2rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        
        h3 {
            font-size: 1.5rem;
            font-weight: 700;
            color: #193880;
            line-height: 130%;
            margin-bottom: 0.875rem;
        }

        h4 {
            font-size: 1rem;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 1rem;
        }

        h5 {
            font-size: 1rem;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 1rem;
        }

        & p {
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            color: #202A39;
        }

        & ul {
            padding: 0 0 0 1rem;
            margin: 0 0 2rem 0;

            li {
                list-style-type: square;
                margin-bottom: 20px;
                padding-left: 10px;
                font-style: normal;

                a {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                }

                &::marker {
                    color: #193880;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .antiCorruptionPage {
        padding: $padding-mobile-AntiCorruption-certificates;
    }
}