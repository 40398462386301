.navItem {
    &.open {
        min-height: 48px;
        line-height: 150%;
        font-size: 1rem;
    }

    &.closed {
        line-height: 120%;
        height: 76px;
        font-size: 10px;
    }

    & a {
        min-height: 64px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #333;
        text-decoration: none;

        &.open {
            justify-content: flex-start;
            flex-direction: row;
            padding: 8px 16px 8px 16px;

            .icon {
                margin: 0 10px 0 0;
            }
        }

        &.closed {
            justify-content: center;
            flex-direction: column;
            padding: 8px 8px 8px 8px;

            .icon {
                margin: 0 0 5px 0;
            }
        }

        .icon {
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
        }

        &:hover {
            transition: 0.3s ease-in-out;
            color: #193880;
            font-weight: 700;
        }
    }
}

@media (max-width: 767.98px) and (orientation: portrait),
(max-width: 767.98px) and (orientation: landscape),
(max-width: 1000px) and (orientation: landscape) {
    .navItem {
        line-height: 120%;
        text-align: left;

        &.open {
            width: 100%;
            height: 56px;
            font-size: 1.25rem;
        }

        &.closed {
            line-height: 150%;
            width: 100%;
            height: 56px;
            font-size: 1.25rem;
        }

        & a {
            &.open {
                justify-content: flex-start;
                flex-direction: row;
                padding: 8px 16px 8px 16px;

                .icon {
                    margin: 0 10px 0 0;
                }
            }

            &.closed {
                justify-content: flex-start;
                flex-direction: row;
                padding: 8px 16px 8px 16px;

                .icon {
                    margin: 0 10px 0 0;
                }
            }

            .icon {
                width: 24px;
                height: 24px;

            }

            &:hover {
                transition: 0.3s ease-in-out;
                color: #193880;
                font-weight: 700;
            }
        }
    }
}