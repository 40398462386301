.footerLocation {
    max-width: 450px;
    padding: 20px 0 0;

    .title {
        font-size: 19px;
        font-weight: 500;
    }

    .content {
        .contactList {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px 0;
            font-size: 13px;

            .responsiveMap {
                width: 450px;
                height: 300px;
                position: relative;
            }
        }
    }
}

@media (max-width: 520px) {
    .footerLocation {
        padding: 20px 0 0;

        .title {
            font-size: 19px;
            font-weight: 500;
        }

        .content {
            .contactList {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px 0;
                font-size: 13px;

                .responsiveMap {
                    width: 356px;
                    height: 300px;
                    position: relative;
                }
            }
        }
    }
}

@media (max-width: 395px) {
    .footerLocation {
        padding: 20px 0 0;

        .title {
            font-size: 19px;
            font-weight: 500;
        }

        .content {
            .contactList {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px 0;
                font-size: 13px;

                .responsiveMap {
                    width: 300px;
                    height: 300px;
                    position: relative;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .footerLocation {
        padding: 20px 0 0;

        .title {
            font-size: 19px;
            font-weight: 500;
        }

        .content {
            .contactList {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px 0;
                font-size: 13px;

                .responsiveMap {
                    width: 256px;
                    height: 256px;
                    position: relative;
                }
            }
        }
    }
}