@import '/src/scss/variables.scss';

.catalogSection {
    width: 100%;
    padding: 3rem 0rem;
    background-color: #f8f9fa;
    text-align: center;

    .catalogContainer {
        max-width: 1300px;
        margin: 0 auto;

        & h2 {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 2rem;
            color: #193880;
        }

        .catalogGrid {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem;

            .catalogCard {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                max-width: 300px;
                width: 100%;
                text-align: center;
                transition: transform 0.3s ease;

                &Content {
                    padding: 1rem;

                    img {
                        max-width: 100%;
                        height: 211px;
                        border-radius: 8px;
                        object-fit: cover;
                        object-position: center;
                    }

                    h3 {
                        font-size: 1.5rem;
                        margin: 1rem 0;
                        color: #333;
                    }

                    p {
                        font-size: 1rem;
                        color: #555;
                        margin-bottom: 1rem;
                    }
                }

                &:hover {
                    transform: translateY(-10px);
                }

                &Link {
                    display: inline-block;
                    padding: 0.8rem 1rem;
                    color: white;
                    background-color: #193880;
                    border-radius: 0 0 8px 8px;
                    text-decoration: none;
                    transition: background-color 0.3s ease;
                }

                &Link:hover {
                    background-color: #1456a0;
                }
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait),
(max-width: 1000px) and (orientation: landscape) {
    .catalogSection {
        padding: $padding-mobile-catalog;

        .catalogContainer {
            & h2 {
                margin-bottom: 1rem;
            }

            .catalogGrid {
                gap: 1rem 0.5rem;

                .catalogCard {
                    width: calc(50% - 0.5rem);
                    transition: transform 0.3s ease;

                    &Content {
                        padding: 0.5rem;
                        word-wrap: break-word;
                        white-space: normal;

                        h3 {
                            margin: 0.5rem 0;
                        }

                        p {
                            margin-bottom: 0rem;
                        }
                    }

                    &:hover {
                        transform: none;
                    }

                    &Link {
                        transition: none;
                    }

                    &Link:hover {
                        background-color: #193880;
                    }
                }
            }
        }
    }
}

@media (max-width: 424.98px) and (orientation: portrait),
(max-width: 424.98px) and (orientation: landscape) {
    .catalogSection {
        padding: 2rem 0.5rem;

        .catalogContainer {
            .catalogGrid {
                .catalogCard {
                    width: 100%;
                }
            }
        }
    }
}