.footerEmail {
    padding: 20px 0 0;

    .title {
        font-size: 19px;
        font-weight: 500;
    }

    .content {
        .contactList {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 25px 0 0;
            font-size: 13px;
        }

        .qualityImage {
            margin-top: 2rem;
            text-align: center;

            img {
                width: 210px;
                height: auto;
            }
        }
    }
}