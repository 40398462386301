.projectsPage {
    padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;

        // .filters {
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 0.5rem 1rem;

        //     button {
        //         padding: 1rem 2rem;
        //         border: none;
        //         background-color: #f0f0f0;
        //         cursor: pointer;
        //         border-radius: 5px;

        //         &.active {
        //             background-color: #193880;
        //             color: #fff;
        //         }
        //     }
        // }

        .cardsContainer {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 1.5rem 1rem;
        }

        .loadMoreButton {
            padding: 0.5rem 1rem;
            border: none;
            background-color: #193880;
            color: #fff;
            cursor: pointer;
        }
    }
}

@media (max-width: 900px) {
    .projectsPage {
        padding: 2rem 1rem;

        .container {
            gap: 1rem;

            .filters {
                button {
                    padding: 1rem 2rem;
                }
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .projectsPage {
        padding: 2rem 1rem;

        .container {
            gap: 1rem;

            .filters {
                button {
                    padding: 0.5rem 1rem;
                }
            }

            .cardsContainer {
                gap: 1rem 0.5rem;
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .projectsPage {
        padding: 2rem 1rem;

        .container {
            gap: 1rem;

            .filters {
                button {
                    padding: 1rem 1rem;
                }
            }
        }
    }
}

@media (max-width: 600px) and (orientation: portrait) {
    .projectsPage {
        .container {
            .filters {
                gap: 0.5rem 0.5rem;

                button {
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}