.certificate {
    width: calc(50% - 1rem);

    & a {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5rem;
        align-content: flex-start;
        align-items: flex-start;
        padding: 0px 0px 0px 40px;
        position: relative;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            fill: #193880;
        }

        .title {
            font-size: 1.125rem;
            font-weight: 500;
            color: #193880;
            line-height: 130%;
        }

        .params {}
    }
}

@media (max-width: 767.98px) and (orientation: portrait) {
    .certificate {
        width: 100%;
    }
}