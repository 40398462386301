.a11yWhyUs {
    .container {
        .cards {
            display: flex;
            justify-content: space-evenly;
            .card {
                text-align: center;
                line-height: 1.5;
            }
        }
    }
}