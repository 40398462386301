.a11yHeader {
    width: 100%;
    padding: 56px 0;

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            display: flex;
            align-items: center;
            color: inherit;

            .logoIcon {
                width: 70px;
                height: 70px;
            }

            .logoText {
                font-size: 24px;
                font-weight: 700;
                line-height: 1.25;
                display: inline;
            }

            .logoTextAlt {
                display: none;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.25;
                white-space: nowrap;
            }

            svg {
                margin: 0 8px 0 0;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 1023.98px) {
    .a11yHeader {
        padding: 16px 0 20px;
        border-bottom: 1px solid;
        border-color: inherit;

        nav {
            a {
                .logoIcon {
                    max-width: 64px;
                    max-height: 64px;
                    width: 100%;
                    height: 100%;
                }

                .logoText {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .a11yHeader {

        nav {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media (max-width: 520px) {
    .a11yHeader {
        nav {
            a {
                .logoText {
                    display: none;
                }

                .logoTextAlt {
                    display: inline;
                }
            }
        }
    }
}