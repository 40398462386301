.productPage {
    padding: 2rem 2rem;
    background-color: #f8f9fa;
    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .productPage {
        padding: 1rem 0.5rem;
    }
}

// .productList {
//     list-style-type: none;
//     padding: 0;
// }

// .productList li {
//     margin: 10px 0;
// }

// .productList a {
//     text-decoration: none;
//     color: blue;
// }

// .productList a:hover {
//     text-decoration: underline;
// }