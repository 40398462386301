.headerControls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .accessibility__btn,
    .search__btn {
        color: #193880;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        line-height: 4rem;
    }

    .icon {
        width: 24px;
        height: 24px;
        fill: #193880;
    }
}