.section {
    width: 100%;
    padding: 2rem 0rem 1rem;
    text-align: center;

    h2 {
        color: #193880;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .swiper {
        max-width: 1300px;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        .swiper-wrapper {
            width: 100%;
            height: 320px;
            display: flex;
            align-items: center;

            .swiper-slide {
                width: 300px;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    position: relative;
                    display: block;
                    width: 100%;
                    // max-height: 225px;
                    height: auto;
                    background-position: center;
                    background-size: cover;
                    border-radius: 8px;
                }

                .slide-text {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(25, 56, 128, 0.5);
                    color: #fff;
                    font-weight: 500;
                    font-size: 1.25rem;
                    padding: 0.875rem;
                    border-radius: 8px;
                    opacity: 0;
                    transform: translateY(20px);
                    transition: opacity 0.3s ease, transform 0.3s ease;
                }

                .slide-text.visible {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .swiper-button-next {
            display: flex;
            justify-content: flex-end;
            padding-right: 4px;
            right: 0;
            top: 0;
            height: 100%;
            margin-top: 39px;
            width: 127px;
            height: 242px;
            background-image: linear-gradient(90deg, #f3f4f500 50%, #F8F9FA);
            color: #193880;

            &::after {
                content: '';
            }

            &::before {
                content: '';
                top: 0;
                right: 0;
                width: 32px;
                height: 32px;
                background-image: url(../../../assets/icons/arrows/arrow_right_alt.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .swiper-button-prev {
            display: flex;
            justify-content: flex-start;
            padding-left: 4px;
            left: 0;
            top: 0;
            height: 100%;
            margin-top: 39px;
            width: 127px;
            height: 242px;
            background-image: linear-gradient(90deg, #F8F9FA, #f3f4f500 50%);
            color: #193880;

            &::after {
                content: '';
            }

            &::before {
                content: '';
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                background-image: url(../../../assets/icons/arrows/arrow_left_alt.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}



@media (max-width: 1000px) and (orientation: landscape) {
    .section {
        h2 {}

        .swiper {
            .swiper-button-next {
                display: none;
            }

            .swiper-button-prev {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .section {
        h2 {}

        .swiper {
            .swiper-button-next {
                display: none;
            }

            .swiper-button-prev {
                display: none;
            }
        }
    }
}


// @media (max-width: 768px) {
//     .swiper {
//         .swiper-button-next {
//             display: none;
//         }

//         .swiper-button-prev {
//             display: none;
//         }
//     }
// }