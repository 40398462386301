.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: rgb(26, 56, 128);
    font-size: 20px;
    font-weight: bold;

    .logoIcon {
        color: #193880;
        width: 56px;
        height: 56px;
    }

    .logoText {
        display: inline;
    }

    .logoTextAlt {
        display: none;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .logo {
        font-size: 1.125rem;

        .logoIcon {
            width: 50px;
            height: 50px;
        }
    }
}

@media (max-width: 769px) and (orientation: portrait) {
    .logo {
        font-size: 1.125rem;

        .logoIcon {
            width: 50px;
            height: 50px;
        }
    }
}

@media (max-width: 520px) {
    .logo {
        .logoText {
            display: none;
        }

        .logoTextAlt {
            display: inline;
        }
    }
}