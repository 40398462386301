@import 'src/scss/variables.scss';

.footer {
    &Expanded {
        margin-left: 250px;
        width: calc(100% - 250px);
    }

    &Collapsed {
        margin-left: 100px;
        width: calc(100% - 100px);
    }

    .footerInner {
        background-color: #f8f9fa;
        display: flex;
        padding: 1rem 2rem;

        .footerContainer {
            max-width: 1300px;
            width: 100%;
            margin: 0 auto;

            a {
                color: $link-normal
            }

            .footerContent {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                margin-bottom: 1rem;
                gap: 25px;
            }
        }
    }

    .footerBottom {
        background-color: #f8f9fa;

        .footerContainer {
            .footerCopy {
                text-align: center;
                padding: 1rem 2rem;
                color: #6c757d;
                font-size: 13px;
                box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
            }
        }
    }
}




@media (max-width: 1255.98px) {
    .footer {
        .footerInner {
            .footerContainer {
                .footerContent {
                    flex-direction: column;
                    flex-wrap: wrap;
                }
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .footer {
        &Expanded {
            margin-left: 0px;
            width: 100%;
        }

        &Collapsed {
            margin-left: 0px;
            width: 100%;
        }

        .footerInner {
            display: flex;
            padding: 1rem 1rem;
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .footer {
        &Expanded {
            margin-left: 0px;
            width: 100%;
        }

        &Collapsed {
            margin-left: 0px;
            width: 100%;
        }

        .footerInner {
            display: flex;
            padding: 1rem 1rem;
        }
    }
}

@media (max-width: 450px) {
    .footer {
        .footerInner {
            padding: 0.5rem 1rem;
        }

        .footerBottom {
            .footerContainer {
                .footerCopy {
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}