@import '/src/scss/variables.scss';

.contactsPage {
    padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        .title {
            font-size: 2.5rem;
            font-weight: 500;
            color: #202A39;
            margin-bottom: 2rem;
            line-height: 150%;
            text-transform: uppercase;
        }

        & h2 {
            font-size: 1.5rem;
            font-weight: 700;
            color: #193880;
            margin-bottom: 10px;
        }

        & h3 {
            font-size: 1.375rem;
            font-weight: 700;
            color: #193880;
            line-height: 130%;
            margin-bottom: 1rem;
            // padding: 2rem 0 0;
        }

        & h4 {
            font-size: 1rem;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 1rem;
        }

        & h5 {
            font-size: 1rem;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 1rem;
        }

        & p {
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            color: #202A39;
            line-height: 150%;
            margin-bottom: 2rem;
        }

        // .tel {
        //     margin-bottom: 0.625rem;
        // }
        .email {
            margin-bottom: 0.625rem;
        }
        & a {
            padding-right: 0.3rem;
        }
    }
}

@media (max-width: 450px) {
    .contactsPage {
        padding: $padding-mobile-AntiCorruption-certificates;

        .container {
            .title {
                font-size: 2.5rem;
                margin-bottom: 2rem;
            }

            & h2 {
                font-size: 1.375rem;
                margin-bottom: 10px;
            }

            & h3 {
                font-size: 1.125rem;
                margin-bottom: 0.5rem;
                padding: 1rem 0 0;
            }

            & h4 {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
            }

            & h5 {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
            }

            & p {
                font-size: 0.875rem;
                margin-bottom: 2rem;
            }

            .tel {
                margin-bottom: 0.625rem;

                & a {
                    padding-right: 0.3rem;
                }
            }
        }
    }
}