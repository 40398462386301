@import '/src/scss/variables.scss';

.metalStructures {
    padding: 3rem 2rem;

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        .content {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 2rem;

            &__image {
                max-width: 58.333333%;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            &__price {
                padding: 0 1rem;

                & span {
                    color: #193880;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }

            &__text {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }


        & h4 {
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 1.25rem;
        }

        & p {
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            color: #202A39;
        }

        & ul {
            padding: 0 0 0 1rem;
            margin: 0 0 2rem 0;

            li {
                list-style-type: square;
                margin-bottom: 20px;
                padding-left: 10px;
                font-style: normal;

                a {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                }

                &::marker {
                    color: #193880;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .metalStructures {
        padding: $padding-mobile-products-card;

        .container {
            .content {
                &__image {
                    max-width: 100%;
                }
            }
        }
    }
}