.searchContainer {
    max-width: 1300px;
    min-height: 50vh;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .searchInput {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .searchResults {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;

        .resultItem {
            background: #f9f9f9;
            border: 1px solid #eee;
            border-radius: 8px;
            padding: 20px;
            max-width: 300px;
            width: calc(33.333% - 40px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            & h3 {
                margin: 0 0 10px;
                font-size: 18px;
                color: #333;
            }

            & img {
                max-width: 100%;
                height: auto;
                margin-bottom: 10px;
            }

            & p {
                margin: 0;
                color: #666;
            }
        }
    }
}

@media (max-width: 1023.98px) {
    .searchContainer {
        .searchInput {}

        .searchResults {
            .resultItem {
                width: 100%;
            }
        }
    }
}